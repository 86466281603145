<script lang="ts" setup>
import BaseUiInputRow from '@dns-modules/base-ui-input-row/base-ui-input-row.vue';
import {ref} from 'vue';

const props = defineProps({
	/** Валидное ли поле */
	isValid: {type: Boolean, default: true},
	/** Основной ли сценарий */
	isScenarioMain: {type: Boolean, default: false},
	/** Поле обязательно к заполнению */
	isRequired: {type: Boolean, default: false}
});

const emit = defineEmits(['set-name', 'check-name-is-valid']);

/** Имя */
const name = ref('');

/** Отображать ли сообщение об ошибке */
const isShowError = ref(false);

/**
 * Установка и передача значения.
 *
 * @param {string} value Значение из поля
 */
const setName = (value: string) => {
	name.value = value;
	emit('set-name', name.value);
};

/** Проверка введённого значения при снятии фокуса с поля */
const check = () => {
	emit('check-name-is-valid');

	nextTick(() => {
		isShowError.value = !props.isValid && props.isRequired;
	});
};

/** Обработка события focus */
const focusHandler = () => {
	isShowError.value = false;
};
</script>

<template>
	<div class="dns-form__row">
		<base-ui-input-row
			:label="isScenarioMain ? 'Имя' : 'Фамилия и Имя'"
			:is-label-shown="true"
			:value="name"
			:maxLength="255"
			:has-error="isShowError"
			:message="'Имя должно быть длиннее одного символа'"
			@input="setName"
			@clickIcon="setName('')"
			@focus="focusHandler"
			@blur="check"
		/>
	</div>
</template>

<style lang="scss"></style>
