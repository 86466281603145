/**
 * Тип заголовков писем при отправке формы.
 */
export const formTitles = {
	ONE_C:      'Заявка на обучение Курсы 1С',
	INTERNSHIP: 'Заявка на стажировку',
	FEEDBACK:   'Обратная связь',
	VACANCY:    'Вакансия',
} as const;

export type FormTitleType = typeof formTitles[keyof typeof formTitles];
