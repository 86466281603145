<script lang="ts" setup>
import BaseUiInputRow from '@dns-modules/base-ui-input-row/base-ui-input-row.vue';
import {ref} from 'vue';

/** Поле Город */
const props = defineProps({
	/** Валидно ли поле */
	isValid: {type: Boolean, default: true},
	/** Поле обязательно к заполнению */
	isRequired: {type: Boolean, default: false}
});

const emit = defineEmits(['set-city', 'check-city-is-valid']);

/** Город */
const city = ref('');

/** Отображать ли сообщение об ошибке */
const isShowError = ref(false);

/** Установка и передача значения */
const setCity = (value: string) => {
	city.value = value;
	emit('set-city', city.value);
};

/** Проверка введённого значения при снятии фокуса с поля */
const check = () => {
	emit('check-city-is-valid');

	nextTick(() => {
		isShowError.value = (!props.isValid || !city.value.length) && props.isRequired;
	})
}

/** Обработка события focus */
const focusHandler = () => {
	isShowError.value = false;
}

</script>

<template>
	<div class="dns-form__row">
		<base-ui-input-row
			label="Город проживания"
			:value="city"
			:is-label-shown="true"
			:has-error="isShowError"
			:message="'Название города должно быть длиннее одного символа'"
			@clickIcon="setCity('')"
			@input="setCity"
			@focus="focusHandler"
			@blur="check"
		/>
	</div>
</template>

<style lang="scss"></style>
