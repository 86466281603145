<script lang="ts" setup>
import BaseUiInputRow from '@dns-modules/base-ui-input-row/base-ui-input-row.vue';
import PhoneMask from '@dns-modules/base-ui-input-row/masks/phone-mask.ts';
import {ref} from 'vue';

/** Поле Телефон */

const props = defineProps({
	/** Поле обязательно к заполнению */
	isRequired: {type: Boolean, default: false}
});

const emit = defineEmits(['set-phone', 'is-invalid']);

const {
		  blurPhoneInput,
		  phone,
		  setPhone,
		  updatingKey,
		  clearPhone,
		  phoneIsValid,
		  checkPhoneIsValid
	  } = usePhoneInput();

/** Ссылка на базовый компонент */
const templatePhone = ref();

/** Отображать ли сообщение об ошибке */
const isShowError = ref(false);

/** Пробросить событие о невалидном значении */
const emitInvalid = () => {
	if (props.isRequired) {
		emit('is-invalid');
	}
}

/**
 * Установка и передача значения.
 *
 * @param {string} value Значение из поля
 */
const inputHandler = (value) => {
	setPhone(value);
	checkPhoneIsValid();

	if (phoneIsValid.value) {
		emit('set-phone', value);

		return;
	}

	emitInvalid();
	if (props.isRequired) {
		emit('is-invalid');
	}
};

/** Обработка события focus */
const focusHandler = () => {
	isShowError.value = false;
};

/** Очистить ввод */
const clearHandler = () => {
	clearPhone();
	emitInvalid();
}

/** Проверка введённого значения при снятии фокуса с поля */
const blurHandler = () => {
	if (props.isRequired || 1 < phone.value.length) {
		checkPhoneIsValid();
		isShowError.value = !phoneIsValid.value;
	}

	nextTick(() => {
		blurPhoneInput();
	})
}
</script>

<template>
	<div class="dns-form__row" ref="templatePhone">
		<base-ui-input-row
			class="dns-form__phone"
			label="Телефон"
			:is-label-shown="true"
			:value="phone"
			:mask="new PhoneMask()"
			:type="'tel'"
			:hasError="isShowError"
			:message="'Неверный формат телефона'"
			:key="updatingKey"
			@input="inputHandler"
			@clickIcon="clearHandler"
			@focus="focusHandler"
			@blur="blurHandler"
		/>
	</div>
</template>

<style lang="scss"></style>
