<script src="./index.ts" lang="ts"></script>

<template>
	<div class="dns-form__wrapper">
		<div class="dns-form">
			<div class="dns-form__inner">
				<div class="dns-form__fields">
					<FormFieldsName
						:is-valid="nameIsValid"
						:is-scenario-main="isScenarioMain"
						:is-required="true"
						@set-name="setName"
						@check-name-is-valid="checkNameIsValid"
					/>
					<template v-if="formTemplates.DEFAULT === formTemplate">
						<div class="dns-form__fields-group">
							<FormFieldsPhone
								class="dns-form__fields-input"
								:is-required="true"
								@set-phone="setPhoneNumber"
							/>
							<FormFieldsEmail
								class="dns-form__fields-input dns-form__fields-input_offset"
								:is-valid="emailIsValid"
								:is-required="true"
								@set-email="setEmail"
								@check-email-is-valid="checkEmailIsValid"
							/>
						</div>
					</template>
					<template v-if="formTemplates.ONE_C === formTemplate">
						<BaseUiDropdown
							class="dns-form__dropdown dns-form__row"
							:selected-option-title="educationalCity"
							:size="'large'"
							:list="boxSelectList"
							:label="'Город'"
							@select="setBoxSelect"
						/>
						<div class="dns-form__fields-group">
							<FormFieldsPhone
								class="dns-form__fields-input"
								:is-required="true"
								@set-phone="setPhoneNumber"
								@is-invalid="setPhoneIsInvalid"
							/>
							<FormFieldsEmail
								class="dns-form__fields-input dns-form__fields-input_offset"
								:is-required="true"
								:is-valid="emailIsValid"
								@set-email="setEmail"
								@check-email-is-valid="checkEmailIsValid"
							/>
						</div>
					</template>
					<template v-if="formTemplates.RESUME === formTemplate">
						<FormFieldsCity
							:is-valid="cityIsValid"
							:is-required="true"
							@set-city="setCity"
							@check-city-is-valid="checkCityIsValid"
						/>
						<div class="dns-form__fields-group">
							<FormFieldsPhone
								class="dns-form__fields-input"
								:is-required="false"
								@set-phone="setPhoneNumber"
							/>
							<FormFieldsEmail
								class="dns-form__fields-input dns-form__fields-input_offset"
								:is-valid="emailIsValid"
								:is-required="true"
								@set-email="setEmail"
								@check-email-is-valid="checkEmailIsValid"
							/>
						</div>
						<FormFieldsResumeLink
							:is-valid="isResumeValid"
							@check-resume-link-value="checkResumeIsValid"
							@enter-value="setResumeLink"
							@load-file="setFile"
							@unload-file="unsetFile"
						/>
						<FormFieldsSocialLink
							@input="setSocial"
							@clickIcon="setSocial('')"
						/>
					</template>
					<template v-if="formTemplates.FEEDBACK === formTemplate">
						<FormFieldsEmail
							:is-valid="emailIsValid"
							:is-required="true"
							@set-email="setEmail"
							@check-email-is-valid="checkEmailIsValid"
						/>
						<FormFieldsDescription
							:is-valid="descriptionIsValidOrEmpty"
							:is-required="true"
							@set-message="setDescription"
							@check-message-is-valid="checkMessageIsValid"
						/>
						<FormFieldsUploadFile
							:isScenarioMain="isScenarioMain"
							@load-file="setFile"
							@unload-file="unsetFile"
						/>
					</template>
				</div>
				<template v-if="!isSend && !isError">
					<div class="dns-form__form-wrap">
						<div :class="['dns-form__checkbox-wrap',{'dns-form__checkbox-wrap_isOneC': isOneC}]">
							<div v-if="isOneC">
								<FormFieldsEmploymentCheckbox
									class="dns-form__fields-input"
									:isValid="isPersonalCheckboxValid"
									@set-value="setEmploymentCheckbox"
								/>
							</div>
							<div>
								<FormFieldsPersonalCheckbox
									class="dns-form__fields-input"
									:is-show-error="!isPersonalCheckboxValid"
									:isScenarioMain="isScenarioMain ? 'main': ''"
									@set-value="setPersonalDataCheckbox"
									@check-personal-checkbox="checkPersonalCheckbox"
								/>
							</div>
						</div>
						<div
							ref="root"
							v-if="hasReCaptcha"
						/>
						<div class="dns-form__send-btn-wrapper">
							<base-ui-button
								class="dns-form__send-btn"
								:title="buttonText"
								:disabled="!isButtonActive"
								:width="'FULL'"
								:size="isMobile ? 'LARGE' : 'MEDIUM'"
								@click-btn="send"
							/>
						</div>
					</div>
				</template>
				<template v-else-if="isError">
					<div class="dns-form__form-wrap">
						<div class="dns-form__error">
							Произошла ошибка. Пожалуйста, повторите позже
						</div>
					</div>
					<div class="dns-form__result-overlay"></div>
				</template>
				<template v-else>
					<div class="dns-form__form-wrap">
						<div v-if=" formTemplates.RESUME === formTemplate" class="dns-form__success">
							Добрый день! Благодарим за отклик на нашу вакансию :)
							<br/>
							Ваше резюме будет рассмотрено. В случае положительного ответа наш специалист свяжется с Вами в течение 3-х рабочих дней.
						</div>
						<div v-else class="dns-form__success dns-form__success_check">
							Сообщение отправлено
						</div>
					</div>
					<div class="dns-form__result-overlay"></div>
				</template>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
@import "assets/styles/variables/respond-to";
@import "assets/styles/_dns-it-icons";

.grecaptcha-badge {
	visibility: hidden;
}

.form {
	background-color: #f9fafc;
	padding:          40px 0 46px 0;

	&__box {
		display:         flex;
		justify-content: center;
		gap:             40px;
	}
}

.dns-form {
	// -- Стили для корректного поведения маски номера телефона
	div[class*="base-ui-input-row"] span {
		letter-spacing: normal;
		font-family:    Calibri, sans-serif;
	}

	input[type=tel] {
		font-family:    Calibri, sans-serif;
		letter-spacing: normal;
	}
	// -- -- --

	// Чтобы стили не переписывали стили базовой кнопки
	button[class*="base-ui-button"] span {
		color:     unset;
		font-size: 18px;
	}

	&__success {
		z-index:       5050;
		display:       block;
		padding:       15px;
		text-align:    left;
		width:         100%;
		background:    rgba(115, 221, 66, .1);
		color:         #58a733;
		border-radius: 8px;
		position:      absolute;
		top:           50%;
		left:          50%;
		transform:     translate(-50%, -50%);

		&_check {
			@extend %dit-check;
			background:      rgba(115, 221, 66, .4);
			display:         flex;
			flex-direction:  row;
			justify-content: center;
			gap:             10px;

			&:before {
				font-size: 19px;
			}
		}
	}

	&__dropdown {
		z-index:  19;
		position: relative;
	}

	&__send-btn-wrapper {
		width: 148px;

		@include respond-to(phone) {
			width: unset;
		}
	}

	&__send-btn {
		@include respond-to(phone) {
			margin-top: 30px;
		}
	}

	&__button {
		width:  148px;
		height: 64px;
	}

	&__form-wrap {
		.dns-form__button {
			line-height: unset;
		}
	}

	&__result-overlay {
		z-index:         5000;
		position:        absolute;
		left:            -5px;
		top:             -5px;
		width:           calc(100% + 10px);
		height:          calc(100% + 10px);
		background:      rgba(249, 250, 252, .5);
		backdrop-filter: blur(4px);
	}

	&__item-inner {
		display:     inline-flex;
		align-items: center;
		user-select: none;

		&_text {
			display:   flex;
			flex-wrap: wrap;
		}
	}

	&__item-inner {
		&:before {
			content:             '';
			display:             inline-block;
			width:               16px;
			height:              16px;
			flex-shrink:         0;
			flex-grow:           0;
			margin-right:        10px;
			background-repeat:   no-repeat;
			background-position: center center;
			background-size:     70% 70%;
		}
	}

	&__item-checkbox:not(:disabled):not(:checked) + &__item-inner:hover::before {
		border-color: #ff9c45;
	}

	&__item-checkbox:not(:disabled):active + &__item-inner::before {
		background-color: #ffb669;
		border-color:     #ff9c45;
	}

	&__item-checkbox:focus + &__item-inner::before {
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
	}

	&__item-checkbox + &__item-inner:before {
		border:        1px solid #adb5bd;
		border-radius: 5px;
	}

	&__item-checkbox + &__item-inner_invalid:before {
		border-color: #cc2e12;
	}

	&__item-checkbox:focus:not(:checked) + &__item-inner:before {
		border-color: #ff9c45;
	}

	&__item-checkbox:checked + &__item-inner {
		@extend %dit-check;
		font-size: 16px;
		color:     #fff;
	}

	&__item-checkbox:checked + &__item-inner::before {
		background-color: #fc8507;
		border-color:     #fc8507;
	}

	&__item-checkbox:disabled + &__item-inner::before {
		background-color: #fff;
	}

	&__text-invalid {
		border: 1px solid #f00;
		color:  #f00;
	}

	&__wrong-lang {
		color:     #f00;
		margin:    0 0 8px 8px;
		font-size: 14px;
	}

	&__inner {
		position:  relative;
		margin:    0 auto;
		max-width: 550px;
	}

	&__fields {
		margin-bottom: 15px;
	}

	&__row {
		width:         100%;
		margin-bottom: 8px;
		font-size:     18px;
		font-family:   "PT Sans", sans-serif;

		div[class^=_base-ui-dropdown__selected] {
			display: flex !important;
		}
	}

	&__error {
		z-index:       5050;
		display:       block;
		padding:       15px;
		text-align:    left;
		width:         100%;
		background:    rgba(255, 92, 82, .1);
		color:         #ea3025;
		border-radius: 8px;
		position:      absolute;
		top:           50%;
		left:          50%;
		transform:     translate(-50%, -50%);
	}

	&__name {
		width:          100%;
		text-transform: capitalize;
	}

	&__fields-group {
		display:         flex;
		justify-content: space-between;
	}

	&__field-wrapper {
		width:          100%;
		display:        flex;
		flex-direction: column;

		&__label {
			transform:  translate(12px, 24px);
			transition: .3s;
			height:     0;
			color:      #8c8c8c;
			font-size:  16px;

			&_up {
				transform: translate(12px, 12px);
				color:     #8c8c8c;
				font-size: 14px;
			}
		}
	}

	&__fields-input {
		&_offset {
			margin-left: 15px;
		}
	}

	&__item-checkbox {
		display: none;
	}

	&__button {
		padding:       20px 32px;
		font-size:     18px;
		font-family:   Calibri, sans-serif;
		outline-color: rgba(100%, 100%, 100%, 1);
	}

	&__item-data {
		position: relative;
		left:     -24px;
	}

	&__form-wrap {
		display:         flex;
		justify-content: space-between;
	}

	&__checkbox-wrap {
		display:         flex;
		flex-direction:  column;
		justify-content: center;

		&_isOneC {
			& > :not(:last-child) {
				margin-bottom: 12px;
			}
		}
	}

	&__item-inner {
		display:   flex;
		font-size: 16px;
	}

	&__item-link {
		color:           #0d61af;
		text-decoration: none;
		font-size:       16px;
	}

	&__square {
		height:        64px;
		border-radius: 8px;
		font-size:     16px;
		box-shadow:    none;
		outline:       0;
		transition:    .3s;
		width:         100%;
		margin-bottom: 8px;
		padding:       18px 0 0 12px;
		color:         #333;

		&_invalid {
			border: 1px solid #cc2e12;
			color:  #cc2e12;
		}

		&_textarea {
			min-height:  150px;
			padding-top: 28px;
		}

		&:hover {
			border-color: #afafaf;
		}
	}

	&__wrapper {
		margin-bottom: 24px;
		flex-grow:     1;
		max-width:     550px;
	}

	&__file-btn, &__file-link {
		font-size: 14px;
		cursor:    pointer;
	}

	&__file-link {
		color: #0d61af;
	}

	&__file-delete {
		border:           none;
		background-color: inherit;
		color:            #cc2e12;
		margin-left:      12px;
		cursor:           pointer;
	}

	&__form-wrapper {
		display: flex;
	}

	&__resume-link {
		height:        64px;
		border-radius: 8px;
		font-size:     16px;
		padding:       18px 0 0 12px;
		box-shadow:    none;
		outline:       0;
		transition:    .3s;
		width:         100%;
		margin-bottom: 8px;
	}

	&__file-wrapper {
		line-height:   17px;
		margin-bottom: 12px;
	}

	&__item {
		font-size: 16px;
	}

	&__link-inner {
		font-size: 16px;
		color:     #0d61af;
	}

	&__phone {
		span {
			line-height:    20px !important;
			letter-spacing: normal;
		}
	}

	&__box-select {
		height:  64px;
		z-index: 1050;
	}

	&__textarea {
		label {
			font-family: "PT Sans", sans-serif;
			color:       #8c8c8c;
		}
	}
}

@media (max-width: $screen-desktop) { //992
	.form {
		padding: 24px 0 40px 0;
		margin:  0;

		&__box {
			display: grid;
			row-gap: 16px;
		}

		&__name {
			width: 100%;
		}
	}

	.dns-form {
		padding: 0;

		&__wrapper {
			margin: 0;
		}
	}
}

@media (max-width: $container-phone) { //576
	.form {
		&__box {
			display: initial;
		}
	}

	.dns-form {
		&__inner {
			width: auto;
		}

		&__fields-group {
			display:       block;
			margin-bottom: 10px;
		}

		&__fields-input {
			width: 100%;

			&_offset {
				margin: 0;
			}
		}

		&__form-wrap {
			display: block;
		}

		&__button {
			min-width: 100%;
		}

		&__fields {
			margin-bottom: 12px;
		}

		&__wrapper {
			margin: 0;
		}

		&__button {
			margin-top: 24px;
		}

		&__item {
			white-space: nowrap;
		}

		&__item-inner {
			&:before {
				width:  24px;
				height: 24px;
			}
		}

		&__item-data {
			left: -34px;
		}

		&__item-checkbox:checked + &__item-inner {
			font-size: 22px;
		}
	}
}

@media (max-width: 411px) {
	.dns-form {

		&__link-inner {
			position: relative;
			right:    150px;
		}

		&__button {
			margin-top: 30px;
		}
	}
}
</style>
