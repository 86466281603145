<script lang="ts" src="./index.ts"></script>

<template>
	<div class="dns-form__file-wrapper">
		<label
			ref="label"
			class="dns-form__file-btn"
			for="file"
			tabindex="0"
			@keyup.enter="openFileLoader"
		>
			<template v-if="!showDeleteButton">
				<template v-if="isScenarioMain">
					<span class="dns-form__file-link" ref="fileLink">Прикрепить файл</span> (не более 15Мб, форматы: doc, docx, txt, pdf, jpg, png)
				</template>
				<template v-else>
					или <span class="dns-form__file-link" ref="fileLink">прикрепить файл</span> (не более 15Мб, форматы: doc, docx, txt, pdf, jpg, png)
				</template>
			</template>
			<template v-else>
				{{fileName}}
			</template>
		</label>
		<input
			v-show="false"
			id="file"
			ref="inputFile"
			class="dns-form__file dns-form__square"
			type="file"
			@change="uploadFile"
		>
		<button
			v-show="showDeleteButton"
			ref="clearButton"
			class="dns-form__file-delete"
			v-on:click="unloadFile"
		>
			Удалить
		</button>
	</div>
</template>

<style lang="scss"></style>
