<script lang="ts" setup>
import BaseUiTextarea from '@dns-modules/base-ui-textarea/base-ui-textarea.vue';

/** Поле с сообщением. */

const props = defineProps({
	/** Валидное ли поле */
	isValid: {type: Boolean, default: true},
	/** Поле обязательно к заполнению */
	isRequired: {type: Boolean, default: false}
});

const emit = defineEmits(['set-message', 'check-message-is-valid']);

/** Сообщение в форме обратной связи */
const message = ref('');

/** Отображать ли сообщение об ошибке */
const isShowError = ref(false);

/**
 * Установка и передача значения.
 *
 * @param {string} value Значение из поля
 */
const setData = (value: string) => {
	message.value = value;
	emit('set-message', message.value);
};


/** Обработка события focus */
const focusHandler = () => {
	isShowError.value = false;
};

/** Проверка введённого значения при снятии фокуса с поля */
const check = () => {
	emit('check-message-is-valid');

	nextTick(() => {
		isShowError.value = (!props.isValid || 0 === message.value.length) && props.isRequired;
	});
};

</script>

<template>
	<div class="dns-form__row">
		<BaseUiTextarea
			class="dns-form__textarea"
			label="Введите сообщение"
			:value="message"
			:has-error="isShowError"
			:dynamic-max-height="250"
			@input="setData"
			@focus-textarea="focusHandler"
			@blur-textarea="check"
		/>
		<FormFieldsFieldError
			v-if="isShowError"
			errorValue='Сообщение должно быть содержательным'
		/>
	</div>
</template>

<style lang="scss"></style>
