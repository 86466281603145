/**
 * Тип шаблона формы.
 */
export const formTemplates = {
	DEFAULT:  'default',
	RESUME:   'resume',
	FEEDBACK: 'feedback',
	ONE_C:    'one-c',
} as const;

export type FormTemplateType = typeof formTemplates[keyof typeof formTemplates];

