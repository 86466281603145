<script lang="ts" setup>
import BaseUiInputRow from '@dns-modules/base-ui-input-row/base-ui-input-row.vue';

/** Поле "Ссылка на соцсети" */

const emit = defineEmits(['enter-value']);

/** Ссылка на соцсети */
const socialLink = ref('');

/**
 * Передача введеного значения.
 *
 * @param value Значение из поля
 */
const setSocialLink = (value: string) => {
	socialLink.value = value;
	emit('enter-value', socialLink.value);
};
</script>

<template>
	<div class="dns-form__field-wrapper">
		<base-ui-input-row
			:label="'Ссылка на соцсети'"
			:is-label-shown="true"
			@input="setSocialLink"
			@clickIcon="setSocialLink('')"
		/>
	</div>
</template>

<style lang="scss"></style>
