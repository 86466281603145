/** Компонент загрузки файла */
export default {
	props: {
		/** Валидное ли значение поля */
		isValid: {type: Boolean, default: true},
		/** Основной ли сценарий */
		isScenarioMain: {type: Boolean, default: true},
	},
	emits: [
		'load-file',
		'unload-file'
	],
	setup(props, {emit}) {
		/** Состояние кнопки "Удалить" */
		const showDeleteButton = ref(false);

		/** Наименование файла */
		const fileName = ref('');

		/** Поле загрузки файла */
		const inputFile = ref();

		/** Лейбл */
		const label = ref();

		/**
		 * Установка и передача значения.
		 * Валидация поля загрузки файлов.
		 */
		const uploadFile = () => {
			const files: FileList | null = inputFile.value.files;

			if (files) {
				const nameOfFile: string = files[0].name;
				const fileSize: number   = files[0].size;
				const fileType: string   = files[0].type;

				const allowableSize: number    = 15728640;
				const availableTypes: string[] = [
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
					'application/msword',
					'application/pdf',
					'text/plain',
					'image/png',
					'image/jpeg',
				];

				if (fileSize <= allowableSize && availableTypes.includes(fileType)) {
					emit('load-file', files[0]);
					fileName.value         = nameOfFile;
					showDeleteButton.value = true;
				}
			}
		};

		/** Состояние поля по умолчанию. */
		const defaultState = () => {
			fileName.value         = '';
			showDeleteButton.value = false;
			inputFile.value        = '';
		}

		/** Сброс файла. */
		const unloadFile = () => {
			defaultState();
			emit('unload-file');
		}

		/** Открытие окна загрузки файла при обращении с клавиатуры. */
		const openFileLoader = () => {
			inputFile.value.click();
		}

		return {
			fileName,
			showDeleteButton,
			label,
			inputFile,
			openFileLoader,
			uploadFile,
			unloadFile,
		};
	}
}
