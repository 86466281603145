<script lang="ts" setup>
import BaseUiCheckbox from '@dns-modules/base-ui-checkbox/base-ui-checkbox.vue';

/** Checkbox на обработку персональных данных */

defineProps({
	/** Выводить ли сообщение об ошибке */
	isShowError: {type: Boolean, default: false},
});

const emit = defineEmits(['set-value', 'check-personal-checkbox']);

/** Значение по умолчанию*/
const isChecked = ref(false);

/** Обработчик изменения состояния */
const switchState = () => {
	isChecked.value = !isChecked.value;
	emit('set-value', isChecked.value);
	emit('check-personal-checkbox', isChecked.value);
}
</script>

<template>
	<div class="dns-form__checkbox-wrapper">
		<BaseUiCheckbox
			title="Обработка персональных данных"
			:checked="isChecked"
			:show-title="false"
			@vue-click="switchState"
		>
			<template #subtitle>
				<div class="dns-form__item-inner_text">
					<span class="dns-form__item">Согласен на обработку &nbsp;</span>
					<a
						class="dns-form__item-link"
						target="_blank"
						href="https://www.dns-shop.ru/rules/personal-data/"
					>
						персональных данных
					</a>
				</div>
			</template>
		</BaseUiCheckbox>
		<FormFieldsFieldError
			v-if="isShowError"
			class="dns-form__error-message"
			errorValue="Подтверждение согласия обязательно!"
		/>
	</div>
</template>

<style lang="scss">
@import "assets/styles/variables/colors";

.dns-form {
	&__error-message {
		margin-top: 4px;
	}
}

.dns-form__item-link, .dns-form__link-inner {
	transition: .5s;

	&:hover {
		color: $brand-primary-base;
	}

	&:hover .dns-form__link-inner {
		color: $brand-primary-base;
	}
}
</style>
