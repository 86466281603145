<script lang="ts" setup>
import BaseUiCheckbox from '@dns-modules/base-ui-checkbox/base-ui-checkbox.vue';

/** Checkbox на трудоустройство */

defineProps({
	/** Валидное ли значение */
	isValid: {type: Boolean, default: true},
});

const emit = defineEmits(['set-value']);

/** Значение по умолчанию*/
const isChecked = ref(false);

/** Обработчик изменения состояния */
const switchState = () => {
	isChecked.value = !isChecked.value;
	emit('set-value', isChecked.value);
}
</script>

<template>
	<div class="dns-form__checkbox-wrapper">
		<BaseUiCheckbox
			:checked="isChecked"
			@vue-click="switchState"
			title="Обработка персональных данных"
			:show-title="false"
		>
			<template #subtitle>
				<div class="dns-form__item-inner_text">
					<span class="dns-form__item">С трудоустройством</span>
				</div>
			</template>
		</BaseUiCheckbox>
	</div>
</template>

<style lang="scss"></style>
