<script lang="ts" setup>
import BaseUiInputRow from '@dns-modules/base-ui-input-row/base-ui-input-row.vue';

/** Поле "Ссылка на резюме" */
const props = defineProps({
	/** Валидное ли поле */
	isValid: {type: Boolean, default: true},
});

const emit = defineEmits(['enter-value', 'load-file', 'unload-file', 'check-resume-link-value']);

/** Ссылка на резюме */
const resumeLink = ref('');

/** Отображать ли сообщение об ошибке */
const isShowError = ref(false);

/** Обработка события загрузки файла */
const loadFile = (file) => {
	isShowError.value = false;
	emit('load-file', file);
};

/** Обработка события удаления файла */
const unloadFile = () => {
	emit('unload-file');
};

/** Проверка введённого значения при снятии фокуса с поля */
const check = () => {
	emit('check-resume-link-value');

	nextTick(() => {
		isShowError.value = !props.isValid;
	});
};

/**
 * Передача введенного значения.
 *
 * @param value Значение из поля
 */
const enterValue = (value: string) => {
	resumeLink.value = value;
	emit('enter-value', resumeLink.value);
};

const focusHandler = () => {
	isShowError.value = false;
};

</script>

<template>
	<div
		ref="resumeLinkWrapper"
		:class="['resume-link', {'resume-link_error': isShowError}]"
	>
		<div class="dns-form__row">
			<base-ui-input-row
				:label="'Ссылка на резюме'"
				:is-label-shown="true"
				:hasError="isShowError"
				:message="'Необходимо заполнить поле или прикрепить файл'"
				@input="enterValue"
				@clickIcon="enterValue('')"
				@focus="focusHandler"
				@blur="check"
			/>
		</div>
		<FormFieldsUploadFile
			:class="['dns-form__row', {'dns-form__file-load': isShowError}]"
			:isScenarioMain="false"
			@load-file="loadFile"
			@unload-file="unloadFile"
		/>
	</div>
</template>

<style lang="scss">
@import "../../assets/styles/variables/respond-to";

.resume-link_error {
	padding-bottom: 44px;

	div[class*="base-ui-input-row__error-msg"] {
		position: absolute;
		bottom:   -76px;

		@include respond-to(phone) {
			bottom: -96px;
		}
	}
}
</style>
