<script lang="ts" setup>
import BaseUiInputRow from '@dns-modules/base-ui-input-row/base-ui-input-row.vue';
import {computed, ref} from 'vue';

/** Поле Email */
const props = defineProps({
	/** Валидно ли поле */
	isValid: {type: Boolean, default: true},
	/** Поле обязательно к заполнению */
	required: {type: Boolean, default: false},
	/** Поле обязательно к заполнению */
	isRequired: {type: Boolean, default: false}
});

const emit  = defineEmits(['set-email', 'check-email-is-valid']);

/** Email */
const email = ref('');

/** Отображать ли сообщение об ошибке */
const isShowError = ref(false);

/** Неправильный язык */
const isWrongLang = ref(false);

/** Сообщение об ошибке */
const errorValue = computed(() => {
	if (isWrongLang.value) {
		return 'Переключите язык на клавиатуре';
	} else if (!props.isValid) {
		return 'Неверный формат почты';
	} else {
		return '';
	}
});

/** Установка и передача значения. */
const setEmail = (value: string) => {
	email.value = value;
	checkLang();

	if (!isWrongLang.value) {
		emit('set-email', email.value);
	}
};

/** Проверка введённого значения при снятии фокуса с поля */
const check = () => {
	emit('check-email-is-valid');

	nextTick(() => {
		isShowError.value =!props.isValid && props.isRequired;
	});

};

/** Обработка события focus */
const focusHandler = () => {
	isShowError.value = false;
}

/** Проверить язык ввода */
const checkLang = () => {
	isWrongLang.value = /[а-яА-ЯЁё]/.test(email.value);

	if (isWrongLang.value) {
		isShowError.value = true;
	}
}
</script>

<template>
	<div class="dns-form__row" >
		<base-ui-input-row
			type="email"
			label="Электронная почта"
			:is-label-shown="true"
			:value="email"
			:has-error="isShowError"
			:message="errorValue"
			@input="setEmail"
			@clickIcon="setEmail('')"
			@focus="focusHandler"
			@blur="check"
		/>
	</div>
</template>

<style lang="scss">
/** Исправление для иконки крестика в BASE-UI-INPUT-ROW */
[class^="_base-ui-input-row__close-icon-container"] {
	z-index: unset !important;
}
</style>
