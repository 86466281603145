import {PropType, ref} from 'vue';
import useIsMobile from '~/composables/useIsMobile';
import {formTemplates, FormTemplateType} from '~/models/form-template';
import {formTitles} from '~/models/form-title';
import {FormScenarioEnum} from './enum/form-scenario.enum';
import BaseUiButton from '@dns-modules/base-ui-button/base-ui-button.vue';
import BaseUiDropdown from '@dns-modules/base-ui-dropdown/base-ui-dropdown.vue';
import {Types} from '@dns-modules/base-ui-dropdown/types';
import {useRecaptchaProvider, useChallengeV2} from 'vue-recaptcha';
import {API_SEND_FORM, API_SEND_RESUME} from '~/api-config';
import {useRoute} from 'vue-router';

/** Форма */
export default {
	components: {
		BaseUiDropdown,
		BaseUiButton,
	},
	props:      {
		/** Есть ли капча */
		hasReCaptcha: {type: Boolean, default: false},
		/** Шаблон формы (набор полей) */
		formTemplate: {type: String as PropType<FormTemplateType>, required: true},
		/** Заголовок */
		title: {type: String, required: true},
		/** Текст кнопки */
		buttonText: {type: String, default: 'Отправить'},
		/** Форма ОС сценарий main. */
		isScenarioMain: {type: Boolean, default: false},
		/** Список для поле "select" */
		boxSelectList: {type: Array, default: []},
	},
	setup(props) {
		useRecaptchaProvider();

		const store = useDnsTech();

		const route = useRoute();

		const {isMobile} = useIsMobile();

		/** Hook для recaptcha */
		const {root, execute, reset, onVerify, onExpired} = useChallengeV2({
			options: {size: 'invisible'}
		});

		/** Сценарий обработки формы */
		const ATTR_SCENARIO = 'scenario';

		/**  Трудоустройство */
		const ATTR_EMPLOYMENT = 'employment';

		/**  Имя */
		const ATTR_NAME = 'name';

		/**  Почта */
		const ATTR_EMAIL = 'email';

		/**  Телефон */
		const ATTR_PHONE = 'phone';

		/**  Город */
		const ATTR_CITY = 'city';

		/**  Город (select) */
		const ATTR_EDUCATIONAL_CITY = 'educationalCity';

		/**  Резюме */
		const ATTR_RESUME = 'resume';

		/**  Сообщение */
		const ATTR_DESCRIPTION = 'description';

		/**  Соцсети */
		const ATTR_SOCIAL = 'social';

		/**  Файл с резюме */
		const ATTR_FILE = 'file';

		/** Название */
		const ATTR_TITLE = 'title';

		/** Вакансия */
		const ATTR_VACANCY_ID = 'vacancyId';

		/** Исключение, для формы курсы 1с */
		const OTHER_CITY = 'Другой город';

		/** Активна ли кнопка отправки */
		const isButtonActive = ref(false);

		/** Трудоустройство */
		const employment = ref('');

		/** Имя */
		const name = ref('');

		/** Почта */
		const email = ref('');

		/** Номер телефона */
		const phoneNumber = ref('');

		/** Город */
		const city = ref('');

		/** Город обучения */
		const educationalCity = ref('Владивосток');

		/** Резюме */
		const resume = ref('');

		/** Сообщение */
		const description = ref('');

		/** Соцсети */
		const social = ref('');

		/** Файл с резюме */
		const file = ref();

		/** Страница "Курсы 1С" */
		const isOneC = computed(() => (formTitles.ONE_C === props.title));

		/**  Проверка имени */
		const nameIsValid = ref(true);

		/** Проверка почты */
		const emailIsValid = ref(true);

		/** Проверка города */
		const cityIsValid = ref(true);

		/** Проверка телефона */
		const phoneIsValid = ref(true);

		/** Проверка сообщения */
		const descriptionIsValidOrEmpty = ref(true);

		/** Отправлена ли форма */
		const isSend = ref(false);

		/** @var Флаг ошибки отправки */
		const isError = ref(false);

		/** Состояние заполнения поля ссылки на резюме для отрисовки ошибки */
		const isResumeLinkEmpty = ref(true);

		/** Состояние заполнения поля ссылки на резюме и прикрепления файла резюме */
		const isResumeValid = ref(true);

		/** Валидно ли состояние чекбокса согласия на обработку персональных данных */
		const isPersonalCheckboxValid = ref(true);

		/** Состояние чекбокса согласия на обработку персональных данных. */
		const isPersonalCheckboxChecked = ref(false);

		/**
		 * Устанавливаем значение поля educationalCity(из dns-box-select).
		 *
		 * @param city Город
		 */
		const setBoxSelect = (city: Types) => {
			if (OTHER_CITY !== city.title) {
				educationalCity.value = city.title;
			}
			else {
				educationalCity.value = '';
			}
		}

		/**
		 * Ввод значений в поле name.
		 *
		 * @param value Вводимое значение
		 */
		const setName = (value: string) => {
			name.value = value;

			if (!nameIsValid.value) {
				checkNameIsValid();
			}
		}

		/**
		 * Ввод значений в поле email.
		 *
		 * @param value Вводимое значение
		 */
		const setEmail = (value: string) => {
			email.value = value;
		}

		/**
		 * Ввод значений в поле description.
		 *
		 * @param value Вводимое значение
		 */
		const setDescription = (value: string) => {
			description.value = value;
		}

		/** Ввод значений в поле phone */
		const setPhoneNumber = (value) => {
			phoneNumber.value  = value;
			phoneIsValid.value = true;
			checkRequiredFields();
		};

		/**
		 * Ввод значений в поле city.
		 *
		 * @param value Вводимое значение
		 */
		const setCity = (value: string) => {
			city.value = value;
		};

		/**
		 * Ввод значений в поле social.
		 *
		 * @param value Вводимое значение
		 */
		const setSocial = (value: string) => {
			social.value = value;
		};

		/** Проверка поля ФИО */
		const checkNameIsValid = () => {
			// Имя обязательно во всех шаблонах формы, поэтому значение не может быть пустым.
			nameIsValid.value = 1 < name.value.length;
			checkRequiredFields();
		};

		/** Проверка поля Город */
		const checkCityIsValid = () => {
			cityIsValid.value = 1 !== city.value.length;
			checkRequiredFields();
		};

		/** Проверка поля Email */
		const checkEmailIsValid = () => {
			// Email обязателен во всех шаблонах формы, поэтому значение не может быть пустым.
			emailIsValid.value = (/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(email.value));
			checkRequiredFields();
		};

		/** Проверка поля ввода сообщения */
		const checkMessageIsValid = () => {
			const length                    = description.value.trim().length;
			descriptionIsValidOrEmpty.value = 2 < length || 0 === length;
			checkRequiredFields();
		};

		/** Проверка состояния чекбокса согласия на обработку персональных данных. */
		const checkPersonalCheckbox = () => {
			isPersonalCheckboxValid.value = isPersonalCheckboxChecked.value;
			checkRequiredFields();
		};

		/** Проверка валидности резюме (оставлены ли ссылка или файл) */
		const checkResumeIsValid = () => {
			if ('' !== resume.value) {
				isResumeValid.value     = true;
				isResumeLinkEmpty.value = false;
			}
			else {
				if (undefined !== file.value) {
					isResumeValid.value = true;
				}
				else {
					isResumeValid.value     = false;
					isResumeLinkEmpty.value = true;
				}
			}
		}

		/**
		 * Установка значения ссылки на резюме.
		 *
		 * @param link Ссылка на резюме
		 */
		const setResumeLink = (link: string) => {
			resume.value = link;
			checkRequiredFields();
		};

		/** Устанавливаем значение нужно ли трудоустройство. */
		const setEmploymentCheckbox = (value: boolean) => {
			employment.value = value ? '1' : '';
		};

		/** Устанавливаем значение "Согласны ли на обработку персональных данных". */
		const setPersonalDataCheckbox = (value: boolean) => {
			isPersonalCheckboxChecked.value = value;
			checkRequiredFields();
		};

		/**
		 * Установка значения файла.
		 *
		 * @param loadedFile Загруженный файл.
		 */
		const setFile = (loadedFile: File) => {
			file.value = loadedFile;

			if (formTemplates.RESUME === props.formTemplate) {
				isResumeLinkEmpty.value = false;
				isResumeValid.value     = true;
			}

			checkRequiredFields();
		};

		/** Сброс значения файла. */
		const unsetFile = () => {
			file.value = undefined;

			if (formTemplates.RESUME === props.formTemplate) {
				if ('' === resume.value) {
					isResumeValid.value     = false;
					isResumeLinkEmpty.value = true;
				}
			}

			checkRequiredFields();
		};

		/** Валидация полей */
		const checkFields = () => {
			checkNameIsValid();
			checkCityIsValid();
			checkEmailIsValid();
			checkMessageIsValid();
			checkPersonalCheckbox();

			let result = (nameIsValid.value && emailIsValid.value && isPersonalCheckboxValid.value);

			if (formTemplates.RESUME === props.formTemplate) {
				result = result && cityIsValid.value && isResumeValid.value;
			}

			if (formTemplates.FEEDBACK === props.formTemplate) {
				result = result && descriptionIsValidOrEmpty.value;
			}

			return result;
		}

		/** Отправка заявки на почту. */
		const send = async () => {
			if (checkFields() && !isSend.value) {
				const formData = new FormData();
				formData.append(ATTR_TITLE, props.title);
				formData.append(ATTR_EMAIL, email.value);
				formData.append(ATTR_NAME, name.value);

				if (formTemplates.ONE_C === props.formTemplate || formTemplates.DEFAULT === props.formTemplate) {
					formData.append(ATTR_PHONE, phoneNumber.value);
				}

				if (formTemplates.RESUME === props.formTemplate) {
					formData.append(ATTR_CITY, city.value);
					formData.append(ATTR_PHONE, phoneNumber.value);
					formData.append(ATTR_RESUME, resume.value);
					formData.append(ATTR_SOCIAL, social.value);
					formData.append(ATTR_VACANCY_ID, <string>route.params.id);
				}

				if (formTemplates.FEEDBACK === props.formTemplate) {
					formData.append(ATTR_DESCRIPTION, description.value);
				}

				if (file.value) {
					formData.append(ATTR_FILE, file.value);
				}

				if (isOneC.value) {
					formData.append(ATTR_SCENARIO, FormScenarioEnum.ONE_C);
					formData.append(ATTR_EMPLOYMENT, employment.value);

					if (educationalCity.value) {
						formData.append(ATTR_EDUCATIONAL_CITY, educationalCity.value);
					}
				}

				if (props.isScenarioMain) {
					formData.append(ATTR_SCENARIO, FormScenarioEnum.MAIN);
				}

				try {
					if (props.hasReCaptcha) {
						execute();
						onVerify(async (token) => {
							const response = await fetch('/check-token', {
								method: 'POST',
								body:   JSON.stringify({token: token}),
							});

							const apiData = await response.json();

							if (!apiData.success) {
								return;
							}

							if (formTemplates.RESUME === props.formTemplate) {
								sendResume(formData);

								return;
							}

							sendForm(formData);
						});

						onExpired(() => {
							reset();
						});

						return;
					}

					if (formTemplates.RESUME === props.formTemplate) {
						sendResume(formData);

						return;
					}

					await sendForm(formData);
				} catch (error) {
					console.log(error);
				}
			}
		}

		/**
		 * Отправка формы.
		 *
		 * @param formData Данные формы
		 */
		const sendForm = async (formData: FormData) => {
			try {
				const response = await $fetch(`${store.dnsSiteHost}${API_SEND_FORM}`, {
					method: 'POST',
					body:   formData,
				});

				if (response.result) {
					isSend.value = true;

					// -- если форма для курса 1С, отправляем метрику
					try {
						if (formTitles.ONE_C === props.title) {
							// @ts-ignore т.к. это внешний подключаемый скрипт
							ym(72421111, 'reachGoal', '1c_training');
						}
					} catch (e) {
					}
					// -- -- --
				}
				else {
					isError.value = true;
				}
			} catch (error) {
				isError.value = true;
			}
		}

		/** Проверяем, что заполнены все обязательные поля */
		const checkRequiredFields = () => {
			// Имя и email заполнены и валидны, чекбокс согласия на обработку данных проставлен
			const isBaseFieldsValid = nameIsValid.value && emailIsValid.value && isPersonalCheckboxChecked.value;

			switch (props.formTemplate) {
				// Поля, обязательные в форме обратной связи: имя, email, сообщение
				case  formTemplates.FEEDBACK:
					isButtonActive.value = isBaseFieldsValid && 2 < description.value.length;
					break;
				// Поля, обязательные в форме заявки на обучение: имя, город, email, телефон
				case formTemplates.ONE_C:
					isButtonActive.value = isBaseFieldsValid && !!educationalCity.value.length && phoneIsValid.value && !!phoneNumber.value;
					break;
				// Поля, обязательные в форме отклика на вакансию: имя, город, email, резюме (ссылка или файл)
				case formTemplates.RESUME:
					isButtonActive.value = isBaseFieldsValid && cityIsValid.value && !!city.value.length && isResumeValid.value;
					break;
				default:
					isButtonActive.value = false;
			}
		};

		/** Установка флага невалидности телефона */
		const setPhoneIsInvalid = () => {
			phoneIsValid.value   = false;
			isButtonActive.value = false;
		}

		/** Отправка резюме */
		const sendResume = async (data: FormData) => {
			const response = await fetch(`${API_SEND_RESUME}`, {
				method: 'POST',
				body:   data,
			});

			const result = await response.json();

			if (result.success) {
				isSend.value = true;
			}
			else {
				isError.value = true;
			}
		}

		return {
			isButtonActive,
			employment,
			name,
			email,
			city,
			educationalCity,
			resume,
			description,
			social,
			file,
			isOneC,
			nameIsValid,
			emailIsValid,
			cityIsValid,
			descriptionIsValidOrEmpty,
			isSend,
			isError,
			isResumeLinkEmpty,
			isResumeValid,
			isPersonalCheckboxValid,
			root,
			setBoxSelect,
			setName,
			setEmail,
			setCity,
			setPhoneNumber,
			setSocial,
			setDescription,
			checkNameIsValid,
			checkCityIsValid,
			checkEmailIsValid,
			checkMessageIsValid,
			checkPersonalCheckbox,
			setResumeLink,
			setFile,
			unsetFile,
			checkFields,
			setEmploymentCheckbox,
			setPersonalDataCheckbox,
			setPhoneIsInvalid,
			send,
			onExpired,
			formTemplates,
			formTitles,
			checkResumeIsValid,
			isMobile
		}
	}
}
